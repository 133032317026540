@import-normalize; // NOTE: if VSCode complains set this rule scss.lint.unknownAtRules to ignore
@import '~react-perfect-scrollbar/dist/css/styles.css';

html,
body {
  width: 100%;
  height: 100%;
  position: relative;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.main-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.MuiTableCell-head {
  white-space: nowrap;
}

.MuiPagination-root .MuiPagination-ul {
  flex-wrap: nowrap;
}
