.offline_registration-modal--overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.513);
  z-index: 10;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.offline_registration-modal {
  position: fixed;
  z-index: 10;
  height: 650px;
  border-radius: 4px;
  width: 800px;
  background-color: white;
  top: 10%;
  right: 25%;
  left: 35%;
  padding: 10px 0px;
  text-align: center;

  form {
    text-align: left;
    display: flex;
    padding: 0px 30px;

    input,
    select {
      padding: 10px 0px;
      margin: 10px 0px;
      width: 340px;
    }
  }

  h4 {
    padding-top: 30px;
  }

  .close {
    float: right;
    margin: 5px 10px;
  }

  .offline_registration-modal--left {
    padding-right: 30px;

    label {
      width: 100px;
    }
  }

  .offline_registration-modal--right {
    label {
      width: 100px;
    }
  }

  .offline_registration-modal--submit {
    margin: 20px 0px;

    input {
      height: 20px;
      width: 50px;
      margin-bottom: 20px;
    }
  }

  .offline_registration-modal--error {
    color: red;
  }
}
